<template>
	<el-aside :width="menuCollapse?'':themeConfig.menuWidth" class="frameAsideCon">

		<el-tabs :tab-position="'left'" type="" v-model="activeName" style="height: 100%" @tab-click="handleClick">
			<el-tab-pane v-for="(item, index) in menusList" :key="index" :name="item.key" v-if="'0' == item.isShow"
				class="frameAside2Scroll thin-scrollbar">
				<span slot="label">
					<el-badge :is-dot="item.count && item.count>0">
						<i style="font-size: 20px;" :class="item.iconcls?item.iconcls:'el-icon-menu'"></i>
					</el-badge>
					<div>
						<!-- {{item.menuName}} -->
						{{ 'en'==$i18n.locale&&item.menuNameEn?item.menuNameEn:item.menuName }}
					</div>
				</span>
				<!-- <div class=""> -->
				<el-menu :default-active="$route.name" :collapse="menuCollapse" :unique-opened="'0'!=themeConfig.uniqueOpened"
					class="frameAside2" :router="true" @select="handleSelect" @open="handleOpen" @close="handleClose">
					<div v-for="(item2, index2) in item.menuDtoList" :key="index2">
						<template v-if="item2.menuDtoList && item2.menuDtoList.length>0">
							<el-submenu :route="{ name: item2.key }" :index="item2.key" v-if="'0' == item2.isShow">
								<template slot="title">
									<div>
										<el-badge :is-dot="item2.count && item2.count>0">
											<i :class="item2.iconcls?item2.iconcls:'el-icon-document'"></i>
										</el-badge>
										<span slot="title">
											<!-- {{ item2.menuName }} -->
											{{ 'en'==$i18n.locale&&item2.menuNameEn?item2.menuNameEn:item2.menuName }}
										</span>
									</div>
								</template>
								<el-menu-item v-for="(item3, index3) in item2.menuDtoList" :key="index3" :route="{ name: item3.key }"
									:index="item3.key" v-if="'0' == item3.isShow">
									<i :class="item3.iconcls?item3.iconcls:'el-icon-document'"></i>
									<span slot="title">
										<el-badge :value="item3.count&&item3.count>0?item3.count:''" :max="9999">
											<span>
												<!-- {{ item3.menuName }} -->
												{{ 'en'==$i18n.locale&&item3.menuNameEn?item3.menuNameEn:item3.menuName }}
											</span>
										</el-badge>
									</span>
								</el-menu-item>
							</el-submenu>
						</template>
						<template v-else>
							<el-menu-item :route="{ name: item2.key }" :index="item2.key" v-if="'0' == item2.isShow">
								<i :class="item2.iconcls?item2.iconcls:'el-icon-document'"></i>
								<span slot="title">
									<el-badge :value="item2.count&&item2.count>0?item2.count:''" :max="9999">
										<span>
											<!-- {{ item2.menuName }} -->
											{{ 'en'==$i18n.locale&&item2.menuNameEn?item2.menuNameEn:item2.menuName }}
										</span>
									</el-badge>
								</span>
							</el-menu-item>
						</template>
					</div>
				</el-menu>
				<!-- </div> -->

			</el-tab-pane>
		</el-tabs>

	</el-aside>
</template>

<script>
	import {
		findTreeNameByKeyList,
		findTreeNameByKey,
		fomatterTipsCount
	} from '@/utils/menus.js';

	import {
		mapGetters
	} from 'vuex';
	export default {
		components: {},
		data() {
			return {
				// Lv1ActiveName: '',
				// setTimer:null,
				activeName: '',
				// Lv2ActiveName: '',
				menusList: [],
				subMenusList: [],
				// countTips: {}
			};
		},
		// watch:{
		// 	'$route'(nVal,oVal){
		// 		console.log(nVal,oVal);
		// 	}
		// },
		watch: {
			$route(nVal, oVal) {
				console.log(nVal, oVal);
				this.queryCount();
				// // this.Lv2ActiveName = nVal.name;
				// let selObjArr = this.findTreeNameByKey(this.menusList,nVal.name);
				// //一级高亮
				// if(selObjArr && selObjArr.length>0){
				// 	if(selObjArr[0].key && '0'==selObjArr[0].isShow){
				// 		this.activeName = selObjArr[0].key;
				// 	}
				// }
				this.highlightLv1();
			}
		},
		computed: {
			...mapGetters({ // 对象形式下 改变映射
				themeConfig: "getThemeConfig"
			}),
			...mapGetters({ // 对象形式下 改变映射
				menuCollapse: "getMenuCollapse"
			}),
		},
		created() {
			console.log('created route', this.$route.name);
			this.init();
			// this.autoAction();
		},
		mounted() {
			console.log('monted');
		},

		methods: {
			init() {
				console.log('init');

				//设置顶部菜单
				this.menusList = this.$store.getters.getMenusInfo;
				console.log('this.menusList', this.menusList);
				if (this.menusList && this.menusList.length > 0) {
					this.activeName = this.menusList[0].key;
				}
				//一级高亮
				this.highlightLv1();

				//统计红点
				this.queryCount();

				// if (this.$store.getters.getMenusInfo) {
				// 	this.menusList = this.$store.getters.getMenusInfo;
				// } else {
				// 	// this.getPremissMenu();
				// }
				// this.menusList.unshift({
				// 	request: 'WHome',
				// 	menuName: this.$t('i18nn_f6e62f9642018cbb'),
				// 	url: 'WHome',
				// 	isShow: '0',
				// 	iconcls: 'el-icon-s-home',
				// 	key: 'WHome',
				// 	menuDtoList:[{
				// 		request: 'Home',
				// 		menuName: this.$t('i18nn_f6e62f9642018cbb'),
				// 		url: 'Home',
				// 		isShow: '0',
				// 		iconcls: 'el-icon-s-home',
				// 		key: 'Home',
				// 	},{
				// 		request: 'Help',
				// 		menuName: '帮助',
				// 		url: 'Help',
				// 		isShow: '0',
				// 		iconcls: 'el-icon-help',
				// 		key: 'Help',
				// 	}],
				// });
			},

			//一级高亮
			highlightLv1() {
				let selObjArr = findTreeNameByKey(this.menusList, this.$route.name);
				//一级高亮
				if (selObjArr && selObjArr.length > 0) {
					if (selObjArr[0].key && '0' == selObjArr[0].isShow) {
						this.activeName = selObjArr[0].key;
					}
				}
			},

			//根据key查询树形对象,返回本级和上级
			// findTreeNameByKey(tree,key){
			// 	for (let i = 0, ln = tree.length; i < ln; i++) {
			// 		let lv1 = tree[i];
			// 		if (key == lv1.key) {
			// 			// selObj = lv1;
			// 			// break;
			// 			return [lv1];
			// 		}
			// 		if (lv1.menuDtoList) {
			// 			for (let j = 0, lnn = lv1.menuDtoList.length; j < lnn; j++) {
			// 				let lv2 = lv1.menuDtoList[j];
			// 				if (key == lv2.key) {
			// 					// selObj = lv2;
			// 					// break;
			// 					return [lv1,lv2];
			// 				}
			// 				if (lv2.menuDtoList) {
			// 					for (let k = 0, lnk = lv2.menuDtoList.length; k < lnk; k++) {
			// 						let lv3 = lv2.menuDtoList[k];
			// 						if (key == lv3.key) {
			// 							return [lv1,lv2,lv3];
			// 						}
			// 					}
			// 				}
			// 			}
			// 		}
			// 	}
			// },

			//根据key查询树形对象,返回本级
			// findTreeNameByKeyList(tree,keyList){
			// 	let lv1Obj = tree.find(item=>{
			// 		return item.key == keyList[0];
			// 	});
			// 	// console.log('lv1Obj',lv1Obj);
			// 	let lv2Obj = {};
			// 	let lv3Obj = {};
			// 	if(keyList.length>=2 && lv1Obj && lv1Obj.menuDtoList && lv1Obj.menuDtoList.length>0){
			// 		lv2Obj = lv1Obj.menuDtoList.find(item=>{
			// 			return item.key == keyList[1];
			// 		});
			// 		// console.log('lv2Obj',lv2Obj);
			// 		if(keyList.length>=3 && lv2Obj && lv2Obj.menuDtoList && lv2Obj.menuDtoList.length>0){
			// 			lv3Obj = lv2Obj.menuDtoList.find(item=>{
			// 				return item.key == keyList[2];
			// 			});
			// 			// console.log('lv3Obj',lv3Obj);
			// 		}
			// 	}
			// 	if(keyList.length==1){
			// 		return lv1Obj;
			// 	} else if(keyList.length==2){
			// 		return lv2Obj;
			// 	} else if(keyList.length==3){
			// 		return lv3Obj;
			// 	}
			// },

			handleSelect(key, keyPath) {
				console.log('handleSelect', key, keyPath);
				let menusList = this.menusList;
				// let selObjArr = this.findTreeNameByKey(menusList,key);
				// let selObj = selObjArr[selObjArr.length-1];
				let selObj = findTreeNameByKeyList(menusList, [this.activeName, ...keyPath]);
				let title = selObj.menuName;
				let titleEn = selObj.menuNameEn;
				if (title && key) {
					this.$store.dispatch('add_history_Tab_Data', {
						title: title,
						titleEn: titleEn,
						routeName: key,
						query: {}
					});
				}
			},
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},

			// handleSelectLv1(key, keyPath) {
			// 	console.log('handleSelect', key, keyPath);
			// 	// this.subMenusList = this.menusList[0].menuDtoList;
			// 	// 	let selObj = {};
			// 	this.Lv1ActiveName = key;
			// 	let menusList = this.menusList;
			// 	let selMenu = menusList.find(item => {
			// 		return item.key == key;
			// 	});
			// 	this.subMenusList = selMenu && selMenu.menuDtoList ? selMenu.menuDtoList : [];

			// 	let subMenuKey = '';
			// 	if (this.subMenusList[0] && this.subMenusList[0].menuDtoList && this.subMenusList[0].menuDtoList.length >
			// 		0) {
			// 		subMenuKey = this.subMenusList[0].menuDtoList[0].key;
			// 	} else {
			// 		subMenuKey = this.subMenusList[0].key;
			// 	}
			// 	this.$router.push({
			// 		name: subMenuKey
			// 	})
			// },
			// handleOpenLv1(key, keyPath) {
			// 	console.log(key, keyPath);
			// },
			// handleCloseLv1(key, keyPath) {
			// 	console.log(key, keyPath);
			// },

			// handleSelectParent(item) {
			// 	this.handleSelectLv1(item.key, '');
			// },

			handleClick(tab, event) {
				console.log(tab, event, this.activeName);
				// if(tab.name==this.activeName){//点击当前一级菜单
				// 	return;
				// }
				let menusList = this.menusList;
				let selMenu = menusList.find(item => {
					return item.key == this.activeName;
				});
				this.subMenusList = selMenu && selMenu.menuDtoList ? selMenu.menuDtoList : [];

				let subMenuKey = '';
				let title = '';
				let titleEn = '';
				if (this.subMenusList[0] && this.subMenusList[0].menuDtoList && this.subMenusList[0].menuDtoList.length >
					0) {
					subMenuKey = this.subMenusList[0].menuDtoList[0].key;
					title = this.subMenusList[0].menuDtoList[0].menuName;
					titleEn = this.subMenusList[0].menuDtoList[0].menuNameEn;
				} else {
					subMenuKey = this.subMenusList[0].key;
					title = this.subMenusList[0].menuName;
					titleEn = this.subMenusList[0].menuNameEn;
				}
				if (this.$route.name === subMenuKey) { //点击当前一级菜单
					console.log(this.$route.name, subMenuKey);
					return;
				}
				this.$router.push({
					name: subMenuKey
				});
				if (title) {
					this.$store.dispatch('add_history_Tab_Data', {
						title: title,
						titleEn: titleEn,
						routeName: subMenuKey,
						query: {}
					});
				}
			},
			//返回红点和红点数量
			// getTipsCount(key){
			// 	return tipsMapByKey(key,this.countTips)
			// },
			//统计红点
			queryCount() {
				this.$http
					.get(this.$urlConfig.HyNavTipCount, {})
					.then(({
						data
					}) => {
						console.log(data);
						if (200 == data.code && data.data) {
							let countTips = data.data;
							try {
								this.menusList = fomatterTipsCount(this.menusList, countTips);
								// console.log('queryCount menusList', this.menusList);
								this.$forceUpdate();
							} catch(err){
								console.log(err);
							}
							
						}
					})
					.catch(error => {
						console.log(error);
					});
			},
			
			// autoAction() {
			// 	clearTimeout(this.setTimer);
			// 	this.setTimer = setTimeout(() => {
			// 		this.queryCount();
			// 		this.autoAction();
			// 	}, 10000);
			// }
		}
	};
</script>

<style>
</style>
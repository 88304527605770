<template>
	<!-- 上传附件 -->
	<el-dialog :title="$t('i18nn_dae8d92a749abc4e')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="800px"
		v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')"
		>
		<div>
			<div style="">
				<el-form ref="modifyPassform" :rules="modifyPassformRules" :model="modifyPassform" label-width="100px"
					>
					
					<el-form-item :label="$t('i18nn_76c9af09324c78c7')" prop="">
					  {{modifyPassform.userName}}
					</el-form-item>
					
					<el-form-item :label="$t('604a0b11e916f387')" required>
						<el-input type="password" show-password v-model="modifyPassform.password" maxlength="50"
							:placeholder="$t('i18nn_e93cb6cc8d5978ed')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('9cb1c9c51e7503d6')" required>
						<el-input type="password" show-password v-model="modifyPassform.repeat_password" maxlength="50"
							:placeholder="$t('i18nn_7f7ce15a429dbf34')"></el-input>
					</el-form-item>

				</el-form>
			</div>
		</div>

		<div slot="footer" class="dialog-footer">
			<el-button type="primary" plain @click="dialogFile = false">{{$t('FormMsg.Close')}}</el-button>
			<el-button type="primary" @click="modifyPassSubmitForm()">{{$t('i18nn_204ffab8a6e01870')}}</el-button>
		</div>
	</el-dialog>
</template>
<script>
	export default {

		props: {
			openTime: {},
			// row: {
			//   default: function() {
			//     return {};
			//   },
			//   type: Object
			// },
		},
		components: {

		},
		data() {
			return {
				dialogFile: false,
				loading: false,

				//修改密码
				modifyPassform: {
					// userId: "",
					// "password": "",
					// "confromPassword": "",
					
					userName:"",
					password:"",
					roleId:"",
					"repeat_password": "", // 确认密码
				},
				modifyPassformRules: {},

			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogFile = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			// initData() {
			// 	//初始化上传控件
			// 	this.modifyPassform = {
			// 		// userId: row.userId,
			// 		"password": "",
			// 		"repeat_password": ""
			// 	};
			// },
			
			initData(){
				this.modifyPassform = {};
			  //数据
			  let userId = this.$store.getters.getUserInfo.id;
			  // if(userId){
			  //   //修改
			  //   this.dialogFormStatus = 1;
			    this.getUserDetData(userId);
			  // } else {
			  //   //新增
			  //   this.dialogFormStatus = 0;
			  // }
			  // //权限组
			  // this.getPremissData();
			  
			},

			//修改密码
			modifyPassSubmitForm(formName) {

				this.$confirm('确定更新密码吗?', this.$t('tips.tipsTitle'), {
						type: 'warning'
					})
					.then(() => {
						let formData = Object.assign({}, this.modifyPassform);
						this.postData(this.$urlConfig.HyAdminUpdate, formData);
					})
					.catch(() => {});
			},
			
			//查询详情接口
			getUserDetData(id) {
			  // let _this = this;
			  this.loading = true;
			  this.$http.post(this.$urlConfig.HyAdminDetail, {
			      "userId": id,
			    })
			    .then(({ data }) => {
			      this.loading = false;
			      if(200==data.code){
			        let form = data.data;
			        this.modifyPassform = Object.assign({}, form);
			      } else {
			        this.$message.warning(data.msg?data.msg:this.$t('i18nn_35da7aebbfd476d0'));
			      }
			      
			    })
			    .catch((error) => {
			      console.log(error);
			      this.$message.error('详情数据，请求失败！');
			      this.loading = false;
			    });
			},
			//提交信息
			// postData(url, formData) {
			// 	this.loading = true;
			// 	this.$http
			// 		.post(url, formData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				this.$alert('恭喜,提交成功！', this.$t('i18nn_cc62f4bf31d661e3'), {
			// 					type: 'success',
			// 				});
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = '提交失败,请重试';
			// 				}
			// 				this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
			// 					type: 'warning',
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('i18nn_a7d2e953195a5588'));
			// 			this.loading = false;
			// 			this.$alert('提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
			// 				type: 'warning',
			// 			});
			// 		});
			// },

			postData(url, formData) {
				this.loading = true;
				this.$http
					.post(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						
						this.loading = false;
						if (200 == data.code) {
							// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							// 	type: 'success'
							// });
							this.dialogFile = false;
							this.$confirm('更新成功，是否去重新登录?', this.$t('tips.tipsTitle'), {
									type: 'success'
								})
								.then(() => {
									this.$router.replace({
										name: 'Login'
									});
								})
								.catch(() => {});


						} else {
							this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
						}
					}).catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loading = false;
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
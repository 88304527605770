<template>
	<!-- <el-dialog :title="$t('7fb6ada66a1fccca')" append-to-body :close-on-click-modal="false" :visible.sync="dialog" width="1000px"> -->
	<el-drawer :wrapperClosable="false" title="" append-to-body :visible.sync="dialog" :size="320">
		<template slot="title">
			<h3><span>{{$t('i18nn_e81d5203b18447dc')}}</span></h3>
		</template>
		<div>
			<el-form ref="form" :model="form" label-width="80px">
				<!-- <el-form-item :label="$t('i18nn_e6c086cadd8732c6')">
					<el-input v-model="form.name"></el-input>
				</el-form-item> -->
				<el-form-item :label="$t('i18nn_9c5a41d10078e21e')">
					<el-select v-model="form.layoutType" placeholder="" @change="onSubmit">
						<el-option :label="$t('i18nn_ac089483cf2b4a0c')" :value="'0'"></el-option>
						<el-option :label="$t('i18nn_fcb613b8ba83ced9')" :value="'1'"></el-option>
						<el-option :label="$t('i18nn_2a51888e02ba42a5')" :value="'2'"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item :label="$t('i18nn_abdfd58e3b842a11')">
					<el-select v-model="form.menuWidth" placeholder="" @change="onSubmit">
						<el-option label="180px" :value="'180px'"></el-option>
						<el-option label="200px" :value="'200px'"></el-option>
						<el-option label="240px" :value="'240px'"></el-option>
						<el-option label="260px" :value="'260px'"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item :label="$t('i18nn_43f72026b0c0699b')">
					<el-select v-model="form.uniqueOpened" placeholder="" @change="onSubmit">
						<el-option :label="$t('i18nn_7a22cefca1bca21d')" :value="'1'"></el-option>
						<el-option :label="$t('i18nn_c3a6ecdfb0f15d2a')" :value="'0'"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item :label="$t('i18nn_2f66aa86e2aa1c1e')">
					<el-switch v-model="form.showTags" :active-text="$t('i18nn_d79e2ed6a2fc66fd')"
						:inactive-text="$t('i18nn_b978a8259ecfca8f')" :active-value="'1'" :inactive-value="'0'"
						@change="onSubmit"></el-switch>
				</el-form-item>

				<el-form-item :label="$t('i18nn_1bd796959e536718')">
					<!-- <el-select v-model="form.colorStyle" placeholder="" @change="onSubmit">
						<el-option label="蓝色" :value="'0'"></el-option>
						<el-option label="白色" :value="'1'"></el-option>
						<el-option label="绿色" :value="'2'"></el-option>
						<el-option label="青色" :value="'3'"></el-option>
						<el-option label="红色" :value="'4'"></el-option>
						<el-option label="紫色" :value="'5'"></el-option>
						<el-option label="黑色" :value="'6'"></el-option>
						<el-option label="黄色" :value="'7'"></el-option>
					</el-select> -->
					<ul class="color-list">
						<li v-for="(item,index) in colorList" :key="index">
							<div class="color-con" :class="form.colorStyle==item.colorVal?'activeColor':''" @click="selColor(item)">
								<div class="color-box" :style="{'background-color':item.color}"></div>
								<div class="color-name">{{item.colorName}}</div>
							</div>
						</li>
					</ul>
				</el-form-item>

				<!-- <el-form-item :label="$t('i18nn_cf47a8351ab58361')">
					<el-col :span="11">
						<el-date-picker type="date" :placeholder="$t('68efabd029c860a9')" v-model="form.date1" style="width: 100%;">
						</el-date-picker>
					</el-col>
					<el-col class="line" :span="2">-</el-col>
					<el-col :span="11">
						<el-time-picker :placeholder="$t('i18nn_00473f5ef0955636')" v-model="form.date2" style="width: 100%;"></el-time-picker>
					</el-col>
				</el-form-item>
				<el-form-item :label="$t('i18nn_612dc996c02cae5d')">
					<el-switch v-model="form.delivery"></el-switch>
				</el-form-item>
				<el-form-item :label="$t('i18nn_593242ce3c67a074')">
					<el-checkbox-group v-model="form.type">
						<el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
						<el-checkbox :label="$t('i18nn_8cb171a4912f9ae5')" name="type"></el-checkbox>
						<el-checkbox :label="$t('i18nn_3e2af40ab8939ecd')" name="type"></el-checkbox>
						<el-checkbox :label="$t('i18nn_c4c6d0906f0299fa')" name="type"></el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<el-form-item :label="$t('i18nn_03b50caa388efd40')">
					<el-radio-group v-model="form.resource">
						<el-radio :label="$t('i18nn_888a17c3151ea785')"></el-radio>
						<el-radio :label="$t('i18nn_478e6c7637d0f1a6')"></el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item :label="$t('i18nn_9d5fa522fb8a6c37')">
					<el-input type="textarea" v-model="form.desc"></el-input>
				</el-form-item> -->
				<el-form-item>
					<el-button type="primary" @click="sureAction">{{$t('204ffab8a6e01870')}}</el-button>
					<!-- <el-button>{{$t('4b3340f7872b411f')}}</el-button> -->
				</el-form-item>
			</el-form>
		</div>

	</el-drawer>

</template>

<script>
	import {
		mapGetters
	} from 'vuex';

	export default {
		props: {
			openTime: {},
		},
		data() {
			return {
				dialog: false,

				colorList: [{
					colorName: this.$t('i18nn_420e047cec52beba'),
					colorVal: "0",
					color: "#29435E"
				}, {
					colorName: this.$t('i18nn_2a6c32718bd62ea0'),
					colorVal: "1",
					color: "#C7C5B8"
				}, {
					colorName: this.$t('i18nn_35681dbc90d33898'),
					colorVal: "2",
					color: "#657362"
				}, {
					colorName: this.$t('i18nn_e0f905492520aeda'),
					colorVal: "3",
					color: "#12373d"
				}, {
					colorName: this.$t('i18nn_80ddb314e4807b49'),
					colorVal: "4",
					color: "#9B414D"
				}, {
					colorName: this.$t('i18nn_bf77a9b5e485df33'),
					colorVal: "5",
					color: "#664B76"
				}, {
					colorName: this.$t('i18nn_9b62de99e77c2111'),
					colorVal: "6",
					color: "#393D3E"
				}, {
					colorName: this.$t('i18nn_43f9e647d7e7ace0'),
					colorVal: "7",
					color: "#C4753C"
				}],
				form: {
					// layoutType: '1',
					// showTags: '1'
				}
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialog = true;
			}
		},
		computed: {
			...mapGetters({ // 对象形式下 改变映射
				themeConfig: "getThemeConfig"
			}),
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {
				console.log('initData');
				this.form = this.themeConfig;
			},
			selColor(item) {
				this.form.colorStyle = item.colorVal;
				this.onSubmit();
			},
			sureAction() {
				// this.$emit('sure', this.form);
				this.onSubmit();
				this.dialog = false;
			},
			onSubmit() {
				console.log('submit!');
				this.$store.dispatch('UPDATE_THEME_CONFIG', this.form);
				if ('2' == this.form.layoutType) { //浮动，其他
					this.$store.dispatch('UPDATE_MENU_COLLAPSE', true);
				} else {
					this.$store.dispatch('UPDATE_MENU_COLLAPSE', false);
				}
				// this.dialog = false;
			}
		}
	};
</script>

<style scoped lang="less">
	.color-list {
		display: flex;
		flex-wrap: wrap;
		li {
			.color-con {
				margin: 0 8px 8px 0px;
				cursor: pointer;
				.color-box {
					margin: 0 auto;
					width: 30px;
					height: 30px;
					border-radius: 5px;
				}
				.color-name {
					text-align: center;
					font-size: 12px;
					line-height: 2;
				}
			}

			.activeColor {
				.color-box {
					box-shadow: 0 0 3px 2px #409eff;
				}
				color:#409eff;
			}
		}
	}
</style>
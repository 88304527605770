<template>
	<el-dialog :title="$t('i18nn_45d1826d9048ca94')" append-to-body :close-on-click-modal="true" :visible.sync="dialog" width="500px">
		<!-- <el-drawer :wrapperClosable="false" title="" append-to-body :visible.sync="dialog" size="1000"> -->
		<template slot="title">
			<h3><span>{{$t('i18nn_45d1826d9048ca94')}}</span></h3>
		</template>
		<div>
			<el-cascader v-model="selRouteName" :options="menusList" :filter-method="filterMethod" filterable clearable :placeholder="$t('i18nn_bc9dde3526b4dcd0')"
				style="width: 100%;" :props="{ 
						expandTrigger: 'hover',
						value:'key',
						label:'menuName',
						children:'menuDtoList'
					}" @change="handleChange">
			</el-cascader>
			<!-- <el-cascader
			    v-model="selRouteName"
			    :options="menusList"
			    :props="{ expandTrigger: 'hover' }"
			    @change="handleChange"></el-cascader> -->
		</div>

		<!-- </el-drawer> -->
	</el-dialog>
</template>

<script>
	// import {
	// 	mapGetters
	// } from 'vuex';
	import {
		findTreeNameByKeyList
	} from '@/utils/menus.js';

	export default {
		props: {
			openTime: {},
		},
		data() {
			return {
				dialog: false,
				menusList: [],
				selRouteName: [],
				// form: {
				// 	// layoutType: '1',
				// 	// showTags: '1'
				// }
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialog = true;
			}
		},
		computed: {
			// ...mapGetters({ // 对象形式下 改变映射
			// 	themeConfig: "getThemeConfig"
			// }),
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {
				console.log('initData');
				this.selRouteName = [];
				let menusList = this.$store.getters.getMenusInfo;
				console.log('menusList', menusList);
				let new_menusList = this.filterMenusShow(menusList);
				this.menusList = new_menusList;
			},
			filterMethod(node, val){
				// console.log(node,val);
				// return node.filter(item=>{
				// 	return item.label.indexOf(keyword)>-1;
				// })
				if(!!~node.text.indexOf(val) || !!~node.text.toUpperCase().indexOf(val.toUpperCase())) {
					return true      
				}
			},
			//显示的菜单
			filterMenusShow(menusList) {
				let new_menusList = menusList.filter(item => {
					if (item.menuDtoList && item.menuDtoList.length > 0) {
						item.menuDtoList = item.menuDtoList.filter(item2 => {
							if (item2.menuDtoList && item2.menuDtoList.length > 0) {
								item2.menuDtoList = item2.menuDtoList.filter(item3 => {
									return '0' == item3.isShow;
								});
							}
							return '0' == item2.isShow;
						})
					}
					return '0' == item.isShow;
				});
				return new_menusList;
			},
			//级联选择
			handleChange(v) {
				console.log(v);
				let routeName = v[v.length - 1];
				this.$router.push({
					name: routeName
				});
				this.dialog = false;

				let selObj = findTreeNameByKeyList(this.menusList, v);
				// console.log('selObj',selObj);
				let title = selObj.menuName;
				if (title && routeName) {
					this.$store.dispatch('add_history_Tab_Data', {
						title: title,
						routeName: routeName,
						query: {}
					});
				}

			},

			//根据key查询树形对象,返回本级
			// findTreeNameByKeyList(tree,keyList){
			// 	let lv1Obj = tree.find(item=>{
			// 		return item.key == keyList[0];
			// 	});
			// 	// console.log('lv1Obj',lv1Obj);
			// 	let lv2Obj = {};
			// 	let lv3Obj = {};
			// 	if(keyList.length>=2 && lv1Obj && lv1Obj.menuDtoList && lv1Obj.menuDtoList.length>0){
			// 		lv2Obj = lv1Obj.menuDtoList.find(item=>{
			// 			return item.key == keyList[1];
			// 		});
			// 		// console.log('lv2Obj',lv2Obj);
			// 		if(keyList.length>=3 && lv2Obj && lv2Obj.menuDtoList && lv2Obj.menuDtoList.length>0){
			// 			lv3Obj = lv2Obj.menuDtoList.find(item=>{
			// 				return item.key == keyList[2];
			// 			});
			// 			// console.log('lv3Obj',lv3Obj);
			// 		}
			// 	}
			// 	if(keyList.length==1){
			// 		return lv1Obj;
			// 	} else if(keyList.length==2){
			// 		return lv2Obj;
			// 	} else if(keyList.length==3){
			// 		return lv3Obj;
			// 	}
			// },
		}
	};
</script>

<style>
</style>

<template>
	<!-- </el-container> -->
	<div style="min-width: 1200px;background-color: #f5f5f5;">
		<el-container>
			<!-- 头部 -->
			<el-header class="myheader" style="">
				<TopHeader></TopHeader>
			</el-header>
			<!-- 路由内容区域 -->
			<!-- <div> -->
				<!-- <keep-alive> -->
				<!-- <router-view/> -->

				<el-container class="frameCon" :style="{ height: $store.state.frameConHeight + 'px' }">
					<!-- 左菜单 -->
					<!-- 常规 -->
					<template v-if="'1'==themeConfig.layoutType">
						<sideMenuGeneral :collapse="false"></sideMenuGeneral>
					</template>
					<!-- 常规 -->
					<template v-else-if="'2'==themeConfig.layoutType">
						<!-- <sideMenuFloat></sideMenuFloat> -->
						<sideMenuGeneral :collapse="true"></sideMenuGeneral>
					</template>
					<!-- 分栏 -->
					<template v-else>
						<sideMenuColumn></sideMenuColumn>
					</template>
					<!-- <sideMenuGeneral></sideMenuGeneral> -->


					<el-main class="" style="padding: 0;">
						<keep-alive>
							<router-view></router-view>
						</keep-alive>
					</el-main>
				</el-container>

				<!-- </keep-alive> -->
			<!-- </div> -->

			<!-- <el-container>

      <el-aside width="200px">Aside</el-aside>
      <el-main>Main</el-main>

    </el-container> -->
		</el-container>
	</div>
</template>
<script>
	import {
		mapGetters
	} from 'vuex';

	import TopHeader from '@/layout/Header/TopHeader.vue';

	import sideMenuGeneral from '@/layout/sideMenuGeneral.vue';
	
	// import sideMenuFloat from '@/layout/sideMenuFloat.vue';

	import sideMenuColumn from '@/layout/sideMenuColumn.vue';

	export default {
		name: 'Frame',
		components: {
			TopHeader,
			sideMenuGeneral,
			// sideMenuFloat,
			sideMenuColumn
		},
		data() {
			return {
				
			};
		},
		
		computed: {
			// ...mapGetters({
			//       gettersHelloOther: "gettersHello" // 对象形式下 改变映射
			//     }),
			// ...mapGetters(['themeConfig']),//数组形式
			...mapGetters({ // 对象形式下 改变映射 
				themeConfig: "getThemeConfig"
			}),
		},
		created() {
			// console.log('created route', this.$route.name);
			this.init();
		},
		mounted() {
			// console.log('monted');
		},
		methods: {
			init() {
				console.log('init');

			},

		}
	};
</script>

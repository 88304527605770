
// 红点和导航映射
// RET": "退货列表",ExchangeReturnList,WarehouseCenter105,WarehouseCenter_p60
// "SDS": "一件代发",WhDropShippingDashboard
// "PLA": "转运预约",TransportPlanOutWh
// "ADDSF": "增值服务",WhWorkOrderList
// "TRF": "自提",WhInShipmentsCommand
// "TRO": "转运出库",TransportOutWh
// "QA": "售后记录",QARecList
// "EXECR": "异常件列表",WhExceptionalEvent
// "SHE90": "异常件上架列表",WhExceptionalEventRecord
// "BRK": "分拆列表",WhSkuSplit
// "SHE10": "入库上架",WhInsStockRecord
// "SHE20": "退货上架",ExchangeReturnInsStock
// "SHE30": "分拆上架"WhSkuSplitInStock
// TSCC //转运入库
// FTR //快速转运
// SCC //入库预报
//unclaimed //无主货UnclaimedGoodsList

const MenusKeysByTipMap = {
	'ExchangeReturnList':'RET',
	'WhDropShippingDashboard':'SDS',
	'TransportPlanOutWh':'PLA',
	'WhWorkOrderList':'ADDSF',
	'WhInShipmentsCommand':'TRF',
	'TransportOutWh':'TRO',
	'QARecList':'QA',
	'WhExceptionalEvent':'EXECR',
	'WhExceptionalEventRecord':'SHE90',
	'WhSkuSplit':'BRK',
	'WhInsStockRecord':'SHE10',
	'ExchangeReturnInsStock':'SHE20',
	'WhSkuSplitInStock':'SHE30',
	'TransportInWh':'TSCC',
	'TransportFastList':'FTR',
	'WhInGoods2':'SCC',
	'UnclaimedGoodsList':'unclaimed'
}

const MenusKeysForTip = Object.keys(MenusKeysByTipMap);

export function fomatterTipsCount(tree,tipsData){
	tree.forEach((item,index)=>{
		if(item.menuDtoList && item.menuDtoList.length>0){
			item.menuDtoList.forEach((item2,index2)=>{
				if(item2.menuDtoList && item2.menuDtoList.length>0){
					item2.menuDtoList.forEach((item3,index3)=>{
						// console.log(item3.key);
						if(MenusKeysForTip.includes(item3.key)){
							// console.log('includes',item3.key);
							item3.count = parseInt(tipsData[MenusKeysByTipMap[item3.key]]);
							if(item3.count && item3.count>0){
								tree[index].count = parseInt(item3.count)+(tree[index].count?parseInt(tree[index].count):0);
								tree[index].menuDtoList[index2].count = parseInt(item3.count)+(tree[index].menuDtoList[index2].count?parseInt(tree[index].menuDtoList[index2].count):0);
							}
						}
					})
				}
			})
		}
	})
	return tree;
}

//根据key查询树形对象,返回本级
export function findTreeNameByKeyList(tree, keyList) {
	let lv1Obj = tree.find(item => {
		return item.key == keyList[0];
	});
	// console.log('lv1Obj',lv1Obj);
	let lv2Obj = {};
	let lv3Obj = {};
	if (keyList.length >= 2 && lv1Obj && lv1Obj.menuDtoList && lv1Obj.menuDtoList.length > 0) {
		lv2Obj = lv1Obj.menuDtoList.find(item => {
			return item.key == keyList[1];
		});
		// console.log('lv2Obj',lv2Obj);
		if (keyList.length >= 3 && lv2Obj && lv2Obj.menuDtoList && lv2Obj.menuDtoList.length > 0) {
			lv3Obj = lv2Obj.menuDtoList.find(item => {
				return item.key == keyList[2];
			});
			// console.log('lv3Obj',lv3Obj);
		}
	}
	if (keyList.length == 1) {
		return lv1Obj;
	} else if (keyList.length == 2) {
		return lv2Obj;
	} else if (keyList.length == 3) {
		return lv3Obj;
	}
}

//根据key查询树形对象,返回本级和上级
export function findTreeNameByKey(tree, key) {
	for (let i = 0, ln = tree.length; i < ln; i++) {
		let lv1 = tree[i];
		if (key == lv1.key) {
			// selObj = lv1;
			// break;
			return [lv1];
		}
		if (lv1.menuDtoList) {
			for (let j = 0, lnn = lv1.menuDtoList.length; j < lnn; j++) {
				let lv2 = lv1.menuDtoList[j];
				if (key == lv2.key) {
					// selObj = lv2;
					// break;
					return [lv1, lv2];
				}
				if (lv2.menuDtoList) {
					for (let k = 0, lnk = lv2.menuDtoList.length; k < lnk; k++) {
						let lv3 = lv2.menuDtoList[k];
						if (key == lv3.key) {
							return [lv1, lv2, lv3];
						}
					}
				}
			}
		}
	}
}

<template>
	<el-aside :width="menuCollapse?'':themeConfig.menuWidth" class="frameAsideCon">
		<el-menu :default-active="$route.name" :collapse="menuCollapse" :unique-opened="'0'!=themeConfig.uniqueOpened"
			class="frameAside" :router="true" @select="handleSelect" @open="handleOpen" @close="handleClose">
			<!-- <el-menu-item :route="{ name: 'Home' }" :index="'Home'">
				<i class="el-icon-s-home"></i>
				<span slot="title">{{$t('i18nn_f6e62f9642018cbb')}}</span>
			</el-menu-item> -->
			<el-submenu v-for="(item, index) in menusList" :key="index" :route="{ name: item.key }" :index="item.key"
				v-if="'0' == item.isShow">
				<template slot="title">
					<el-badge :is-dot="item.count && item.count>0">
						<i :class="item.iconcls?item.iconcls:'el-icon-menu'"></i>
					</el-badge>
					<span slot="title">
						{{ 'en'==$i18n.locale&&item.menuNameEn?item.menuNameEn:item.menuName }}
					</span>
				</template>

				<div v-for="(item2, index2) in item.menuDtoList" :key="index2">
					<template v-if="item2.menuDtoList && item2.menuDtoList.length>0">
						<el-submenu :route="{ name: item2.key }" :index="item2.key" v-if="'0' == item2.isShow">
							<template slot="title">
								<div>
									<el-badge :is-dot="item2.count && item2.count>0">
										<i :class="item2.iconcls?item2.iconcls:'el-icon-document'"></i>
									</el-badge>
									<span slot="title">
										<!-- {{ item2.menuName }} -->
										{{ 'en'==$i18n.locale&&item2.menuNameEn?item2.menuNameEn:item2.menuName }}
									
									</span>
								</div>
							</template>
							<el-menu-item v-for="(item3, index3) in item2.menuDtoList" :key="index3" :route="{ name: item3.key }"
								:index="item3.key" v-if="'0' == item3.isShow">
								<i :class="item3.iconcls?item3.iconcls:'el-icon-document'"></i>
								<span slot="title"><el-badge :value="item3.count&&item3.count>0?item3.count:''" :max="9999">
										<span>
											<!-- {{ item3.menuName }} -->
											{{ 'en'==$i18n.locale&&item3.menuNameEn?item3.menuNameEn:item3.menuName }}
										</span>
									</el-badge></span>
							</el-menu-item>
						</el-submenu>
					</template>
					<template v-else>
						<el-menu-item :route="{ name: item2.key }" :index="item2.key" v-if="'0' == item2.isShow">
							<i :class="item2.iconcls?item2.iconcls:'el-icon-document'"></i>
							<span slot="title"><el-badge :value="item2.count&&item2.count>0?item2.count:''" :max="9999">
									<span>
										<!-- {{ item2.menuName }} -->
										{{ 'en'==$i18n.locale&&item2.menuNameEn?item2.menuNameEn:item2.menuName }}
									</span>
								</el-badge></span>
						</el-menu-item>
					</template>

				</div>

			</el-submenu>

		</el-menu>
	</el-aside>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import {
		findTreeNameByKeyList,
		fomatterTipsCount
	} from '@/utils/menus.js';

	export default {
		components: {},
		// props: {
		// 	collapse: {
		// 		default: function() {
		// 			return false;
		// 		},
		// 		type: Boolean
		// 	},
		// },
		data() {
			return {
				menusList: [],
			};
		},
		watch: {
			$route(nVal, oVal) {
				console.log(nVal, oVal);
				this.queryCount();
			}
		},
		computed: {
			...mapGetters({ // 对象形式下 改变映射
				themeConfig: "getThemeConfig"
			}),
			...mapGetters({ // 对象形式下 改变映射
				menuCollapse: "getMenuCollapse"
			}),
		},
		created() {
			console.log('created route', this.$route.name);
			this.init();
		},
		mounted() {
			console.log('monted');
		},

		methods: {
			init() {
				console.log('init');

				//设置顶部菜单
				// this.menusList = this.$store.getters.getMenusInfo;
				// console.log('this.menusList', this.menusList);
				
				let menusList = this.$store.getters.getMenusInfo;
				if (menusList && menusList.length>0) {
					this.menusList = menusList;
				} else {
					// this.getPremissMenu();
					// setTimeout(()=>{
					// 	this.menusList = menusList;
					// },500);
				}
				this.queryCount();
			},

			//根据key查询树形对象,返回本级和上级
			// findTreeNameByKey(tree,key){
			// 	for (let i = 0, ln = tree.length; i < ln; i++) {
			// 		let lv1 = tree[i];
			// 		if (key == lv1.key) {
			// 			// selObj = lv1;
			// 			// break;
			// 			return [lv1];
			// 		}
			// 		if (lv1.menuDtoList) {
			// 			for (let j = 0, lnn = lv1.menuDtoList.length; j < lnn; j++) {
			// 				let lv2 = lv1.menuDtoList[j];
			// 				if (key == lv2.key) {
			// 					// selObj = lv2;
			// 					// break;
			// 					return [lv1,lv2];
			// 				}
			// 				if (lv2.menuDtoList) {
			// 					for (let k = 0, lnk = lv2.menuDtoList.length; k < lnk; k++) {
			// 						let lv3 = lv2.menuDtoList[k];
			// 						if (key == lv3.key) {
			// 							return [lv1,lv2,lv3];
			// 						}
			// 					}
			// 				}
			// 			}
			// 		}
			// 	}
			// },
			//根据key查询树形对象,返回本级
			// findTreeNameByKeyList(tree,keyList){
			// 	let lv1Obj = tree.find(item=>{
			// 		return item.key == keyList[0];
			// 	});
			// 	// console.log('lv1Obj',lv1Obj);
			// 	let lv2Obj = {};
			// 	let lv3Obj = {};
			// 	if(keyList.length>=2 && lv1Obj && lv1Obj.menuDtoList && lv1Obj.menuDtoList.length>0){
			// 		lv2Obj = lv1Obj.menuDtoList.find(item=>{
			// 			return item.key == keyList[1];
			// 		});
			// 		// console.log('lv2Obj',lv2Obj);
			// 		if(keyList.length>=3 && lv2Obj && lv2Obj.menuDtoList && lv2Obj.menuDtoList.length>0){
			// 			lv3Obj = lv2Obj.menuDtoList.find(item=>{
			// 				return item.key == keyList[2];
			// 			});
			// 			// console.log('lv3Obj',lv3Obj);
			// 		}
			// 	}
			// 	if(keyList.length==1){
			// 		return lv1Obj;
			// 	} else if(keyList.length==2){
			// 		return lv2Obj;
			// 	} else if(keyList.length==3){
			// 		return lv3Obj;
			// 	}
			// },

			handleSelect(key, keyPath) {
				console.log('handleSelect', key, keyPath);
				// let selObj = {};
				let menusList = this.menusList;
				// let selObjArr = this.findTreeNameByKey(menusList,key);
				// let selObj = selObjArr[selObjArr.length-1];
				let selObj = findTreeNameByKeyList(menusList, keyPath);
				// for (let i = 0, ln = menusList.length; i < ln; i++) {
				// 	for (let j = 0, lnn = menusList[i].menuDtoList.length; j < lnn; j++) {
				// 		if (key == menusList[i].menuDtoList[j].key) {
				// 			selObj = menusList[i].menuDtoList[j];
				// 			break;
				// 		}
				// 	}
				// }

				let title = selObj.menuName;
				if (title && key) {
					this.$store.dispatch('add_history_Tab_Data', {
						title: title,
						routeName: key,
						query: {}
					});
				}
			},
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClick(tab, event) {
				console.log(tab, event);
			},
			//统计红点
			queryCount() {
				this.$http
					.get(this.$urlConfig.HyNavTipCount, {})
					.then(({
						data
					}) => {
						console.log(data);
						if (200 == data.code && data.data) {
							let countTips = data.data;
							try {
								this.menusList = fomatterTipsCount(this.menusList, countTips);
								console.log('queryCount menusList', this.menusList);
								this.$forceUpdate();
							} catch (err) {
								console.log(err);
							}

						}
					})
					.catch(error => {
						console.log(error);
					});
			},
		}
	};
</script>

<style>
</style>